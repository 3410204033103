* { 
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

header {
  width: 100%;
  min-height: 10vh;
  background-color: #9E2a2a;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 15px;
}

header button {
  color: white;
  background-color: transparent;
  border: none;
  padding: 15px;
  font-size: 20px;
}

/* Header Logo Style */
.logo{
  padding: 17px;
  width: 5vw;
  height: 10vh;

}

.logo:hover {
  transition: 0.4s;
  cursor: pointer;

}
/* ======= */
p {
  font-family: Tofino;
}

button p{
  font-size: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
button p:hover{
  transition-duration: 0.1s;
  cursor: pointer;
  color: #E25959;
}

.button-container {
  width: auto; 
  display: flex;
  justify-content: center;
  align-items: center; 
  padding-left: 7px;
  padding-right: 7px;


}

.button-selected{
  color: #E25959;
}
/* === FOOTER === */

.fixed-footer {
  border-top: 2px solid #9E2a2a;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 50px;
  background-color: white;
  color: #DA2A2B;
  text-align: left;
  font-size: 15px;
  text-indent: 2px;
  font-family: Tofino;
  font-weight: normal;
  z-index: 1;
}

.fixed-footer p{
  margin:  15px;
}

/* === Sammy's CSS === */

/* Reset CSS */
html {
overflow-y: scroll;
}

/* Font Information */

@font-face {
  font-family: "Tofino";
  font-weight: normal;
  src: url("./styles/Fonts/TofinoPersonal-Regular.woff");
}

.work{
  display: flex;
  min-height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 0;
}

/* === NAV BEGIN === */

/* .fixed-header{
      top: 0;
}

.nav-menu ul{
  position: fixed;
  display: inline;
  width: 100%;
  padding: 20px;
  list-style: none;
  text-align: center;
  background-color: #9E2A2A;    
  margin-top: -150px;
  z-index: 100;
}

.nav-menu li a{
  float: left;
  display: inline;
  padding: 15px;
  padding-left: 20px;
  color: #DA2A2B;
  text-decoration: none;
  font-family: Tofino;
  font-weight: bold;
  font-size: 20px;
} */

/* Header Menu Active Style */
/* .nav-menu li a:active {
  color: white;
} */

/* Header Menu Mouse-Over Style */
/* .nav-menu li a:hover {
  color: white;
  transition: 0.1s;
} */

/* === NAV END === */



/* === GALLERY === */

/* .filter {
margin-top: -45px;;
padding:0;
list-style:none;
  
}

.filter li {
  float:left;
}

.filter li span {
  display: block;
  padding:5px 20px;		
  text-decoration:none;
  color:#666;
  cursor: pointer;
}

.filter li span.active {
  background: #9E2A2A;
  color:#fff;
} */

.filter{
  font-family: Tofino;
  text-decoration: none;
  height: 10px;
  width: 13px;
  position: relative;
  padding: 2px;

}

li{
  margin: 10px;
}

.filter:hover {
  cursor: pointer;
  color: #9E2A2A;
}
.selected {
  color: #9E2A2A;
  list-style: circle;

  /* color:#fff; */
}
.list-container {
  padding: 30px;
  list-style-type: none;
  position: fixed;
  left: 0;
  z-index: 0;
}

.portfolio-wrapper{
  margin: auto;
}

.masonry{
  position: relative;
  margin-left: 200px;
  margin-right: 200px;
  margin-bottom: 200px;
  border: 2px solid black;
  border-top: none;
  min-height: 100vh;
  width: 100vw;
  z-index: -1;
}

.about{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  height: 60vh;
}

.paragraph-container{
  height: 60vh;

  border: 1px solid black;
}

.paragraph{
  border: 1px solid black;
}
.about-pic{
  border: 1px solid black;
  background-image: url("./img/ROJI@3x.png");
}
/*
body{
  z-index: 80;
  margin-top: 50px;
  padding-top: 100px;
  padding-bottom: 40px;

}

.container { 
position: relative; 
width: 960px; 
margin: 0 auto; 
-webkit-transition: all 1s ease;
-moz-transition: all 1s ease;
-o-transition: all 1s ease;
transition: all 1s ease;	
}

#portfoliolist .portfolio {
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
-o-box-sizing: border-box;
width:23%;
margin:1%;
display:none;
float:left;
overflow:hidden;
}

.portfolio-wrapper {
overflow:hidden;
position: relative !important;
background: #666;
cursor:pointer;
}

.portfolio img {
max-width:100%;
position: relative;
top:0;
  -webkit-transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:         all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);		
}

.portfolio .label {
position: absolute;
width: 100%;
height:40px;
bottom:-40px;
  -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition:         all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.portfolio .label-bg {
  background: #e95a44;
  width: 100%;
  height:100%;
  position: absolute;
  top:0;
  left:0;
}

.portfolio .label-text {
  color:#fff;
  position: relative;
  z-index:500;
  padding:5px 8px;
}

.portfolio .text-category {
  display:block;
  font-size:9px;
}

.portfolio:hover .label {
  bottom:0;
}
.portfolio:hover img {
  top:-30px;
}   */


/* === WORK CONTENT END === */



/* === RESPONSIVE INTERVENTIONS === */
  
/* #Tablet (Portrait) */
/* @media only screen and (min-width: 768px) and (max-width: 959px) {
.container {
  width: 768px; 
}
} */

/*  #Mobile (Portrait) - Note: Design for a width of 320px */
@media only screen and (max-width: 767px) {
  header {
    width: 100vw;
    min-height: 10vh;
    background-color: #9E2a2a;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 15px;
  }

  .masonry{
      position: relative;
      margin-left: 120px;
      margin-right: auto;
      margin-bottom: 100px;
      border: 2px solid black;
      border-top: none;
      min-height: 100vh;
      width: 70vw;
      z-index: -1;

  }

  .list-container {
    padding: 0px;
    list-style-type: none;
    position: fixed;
    left: 0;
    z-index: 0;
  }

  /* .work{
    display: flex;
  } */
}

/* #Mobile (Landscape) - Note: Design for a width of 480px */
/* @media only screen and (min-width: 480px) and (max-width: 767px) {
.container {
  width: 70%;
}
} */